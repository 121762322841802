<template>
  <v-container>
  <v-data-table style="width: 100%;" :headers="headers" :items="items" item-key="name" class="elevation-1">
    <!--ACTIONS-->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex flex-no-wrap">
          <v-icon v-if="item === items[items.length - 2] && item.active === true && !item.is_editing && !item.exoneration"
            size="x-large" color="red" @click="confirmExonerateItem(item)" title="Exonerar">
            mdi-alert-octagon-outline
          </v-icon>
          <v-menu bottom left v-if="item !== items[items.length - 1] && !item.is_editing">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon :class="{'pl-12': item !== items[items.length - 2] || !item.active}" v-if="item.active === true" color="black" size="x-large">mdi-dots-vertical
                </v-icon>
                <v-icon v-else class="pl-12" color="black" size="x-large">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="editItem(item)">
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="confirmDeleteItem(item)">
                <v-list-item-title>Deletar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!--ícone para cancelar edição-->
          <v-icon v-if="item.is_editing" size="x-large" color="red" @click="confirmNotEditItem(item)" title="Cancelar">
            mdi-cancel
          </v-icon>
          <v-icon v-if="item.is_editing" class="pl-1" size="x-large" color="green" @click="confirmUpdateCargo(item)" title="Confirmar">
            mdi-check-circle
          </v-icon>
        </div>
        <v-icon class="pl-7" v-if="item === items[items.length - 1]" size="x-large" color="green" @click="confirmRegistraCargo(item)">
          mdi-plus-circle
        </v-icon>
      </template>

    <!-- Campos de entrada para informações do cargo -->

    <!--CARGO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.post="{ item }">
      <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.post" required label="Cargo*"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.post
          }}</span>
      </template>
    </template>
    <!--Vínculo-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.bond="{ item }">
      <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.bond" required label="Vínculo*"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.bond
          }}</span>
      </template>
    </template>
    <!--JOBREGISTER-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.jobRegister="{ item }">
      <v-text-field auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.jobRegister" v-mask="'##########'" label="Matrícula*"
        :rules="[rules.required]"></v-text-field>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.jobRegister
          }}</span>
      </template>
    </template>

    <!--DEPARTAMENTO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.department="{ item }">
      <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.department" required label="Setor*"
        :rules="[rules.required]">
      </v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.department
          }}</span>
      </template>
    </template>

    <!--FUNÇÃO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.role="{ item }">
      <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.role" label="Função*"
        :rules="[rules.required]">
      </v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.role
          }}</span>
      </template>
    </template>

    <!--DATA DE ENTRADA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.entryDate="{ item }">
      <v-text-field v-if="item === items[items.length - 1] || item.is_editing" v-model="item.entryDate" label="Admissão*" type="date"
       :rules="[rules.required, validateDate]"></v-text-field>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{
          formatDate(item.entryDate) }}</span>
      </template>
    </template>

    <!--EXONERAÇÃO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.exoneration="{ item }">
      <v-text-field v-if="item === items[items.length - 1] || item.is_editing" v-model="item.exoneration" label="Exoneração" type="date"
       :rules="[isExonerationDateValid]"></v-text-field>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{
          formatDate(item.exoneration) }}</span>
      </template>
    </template>

    <!--CHEFIA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.isBoss="{ item }">
      <v-select v-if="item === items[items.length - 1] || item.is_editing" v-model="item.isBoss" :items="isBossSelector" label="Chefia*"
        :rules="[rules.required]"></v-select>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.isBoss ?
          'Sim' : 'Não' }}</span>
      </template>
    </template>

    <!--SETOR DE CHEFIA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.bossSector="{ item }">
      <v-textarea rows="1" auto-grow v-if="(item === items[items.length - 1] || item.is_editing)  && item.isBoss == 'Sim' " v-model="item.bossSector" label="Setor que chefia*"
        :color="item.color" required :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.bossSector
          }}</span>
      </template>
    </template>

    <!--SIMBOLO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.symbol="{ item }">
      <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.symbol" required label="Símbolo*"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.symbol
          }}</span>
      </template>
    </template>

    <!--CCA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.cca="{ item }">
      <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.cca" required label="CCA*"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.cca
          }}</span>
      </template>
    </template>

    <!--CIDADE DE LOTAÇÃO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.workCity="{ item }">
      <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.workCity" required label="Cidade Lotação*"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.workCity
          }}</span>
      </template>
    </template>

    <!--ORGÃO DE ORIGEM-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.originOrganization="{ item }">
      <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.originOrganization" required label="Orgão origem"
        :color="item.color"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.originOrganization
          }}</span>
      </template>
    </template>

    <!--BÔNUS PÓS APOSENTADORIA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.bonusPostRetirement="{ item }">
      <v-select v-if="item === items[items.length - 1] || item.is_editing" v-model="item.bonusPostRetirement" :items="bonusPostRetirementSelector" label="Abono Permanência*" item-value="value"
        :rules="[rules.required]"></v-select>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.bonusPostRetirement ?
          'Sim' : 'Não' }}</span>
      </template>
    </template>
  </v-data-table>

  <!-- Diálogo de confirmação de modificação -->
  <template>
  <v-row justify="center">
    <v-dialog 
      v-model="dialog_update" persistent max-width="420" 
      transition="dialog-bottom-transition"
    >
      <v-card class="elevation-10">
        <v-card-title 
          class="text-h5 d-flex align-center" 
          color="primary lighten-5"
        >
          <span class="">{{ dialog_phrase }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
          
        <v-divider></v-divider>

        <v-card-actions class="justify-end px-4">
          <v-btn 
            color="red" 
            text
            class="elevation-1"  
            @click="cancelDialog"
          >
            <v-icon left>mdi-close</v-icon>
            Não
          </v-btn>
          <v-btn color="green" class="elevation-1 white--text" 
            text-color="white" @click="confirmDialog"
          >
            <v-icon left>mdi-check</v-icon>
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

</v-container>
</template>

<script>

const urlbackend = process.env.VUE_APP_API_URL
import axios from 'axios'

export default {
  watch: {
    person: {
      handler() {
        if(this.person.ID){
          this.fetchCareerData()
        }
      },
      deep: true,
      immediate: true
    },
    items: {
      handler(newItems) {
        newItems.forEach(item => {
          if (item.isBoss === 'Não') {
            item.bossSector = '';
          }
        });
      },
      deep: true,
    },
  },
  props: {
    value: Array,
    person: Object,
    update: Boolean,
  },
  data() {
    return {
      dialog_update: false,
      confirm_update: false,
      dialog_phrase: "",
      itemToModify: null,
      actionToConfirm: null,
      // Objeto para armazenar o item que está sendo editado
      copia_item: {},
      isBossSelector: ["Sim", "Não"],
      bonusPostRetirementSelector: ["Sim", "Não"],
      rules: {
        required: (value) => !!value || "Campo Obrigatório"
      },
      fetchedPerson: {},
      headers: [
        { text: 'Matrícula', value: 'jobRegister', sortable: false, align:'center', width: '120px' },
        { text: 'Cargo', value: 'post', sortable: false, width: '100px', align:'center' },
        { text: 'Vinculo', value: 'bond', sortable: false, width: '100px', align:'center' },
        { text: 'Setor', value: 'department', sortable: false, width: '100px', align:'center' },
        { text: 'Função', value: 'role', sortable: false, align:'center', width: '130px' },
        { text: 'Admissão', value: 'entryDate', sortable: false , width:'120px', align:'center'},
        { text: 'Exoneração', value: 'exoneration', sortable: false,  width: '120px', align:'center' },
        { text: 'Chefia', value: 'isBoss', sortable: false },
        { text: "Setor que Chefia", value: "bossSector", sortable: false, width: '130px', align:'center' },
        { text: "Símbolo", value: "symbol", sortable: false },
        { text: "CCA", value: "cca", sortable: false, width: '100px', align:'center' },
        { text: "Cidade Lotação", value: "workCity", sortable: false,  width: '130px', align:'center' },
        { text: "Orgão Origem", value: "originOrganization", sortable: false,  width: '130px', align:'center' },
        { text: "Abono de Permanência", value: "bonusPostRetirement", sortable: false,  width: '160px', align:'center' },
        { text: "Ações", value: "actions", sortable: false }
      ],
      items: [
        {
          jobRegister: '',
          post: '',
          department: '',
          role: '',
          bond: '',
          entryDate: '',
          exoneration: '',
          isBoss: '',
          bossSector: '',
          cca: '',
          symbol: '',
          workCity: '',
          originOrganization: '',
          bonusPostRetirement: '',
          is_editing: false,
          is_disabled: false
        }
      ],
    }
  },
  mounted() {
  this.items = this.person.careers.map(el => ({
    ...el,
    is_disabled: false,
    is_editing: false,
  }));

  // Adiciona o objeto para um novo cargo no final do array
  this.items.push(this.createEmptyCareer());
},
  methods: {
    //Usado para formatar a data para o formato yyyy-mm-dd
    formatNewDate(value) {
    if (!value) return '';
    var date = new Date(value);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  },
    // Tira o formato ISO da data para exibir pro usuário (##/##/####)
    formatDate(value) {
      if (!value) return ''
      var date = new Date(value)
      var userTimezoneOffset = date.getTimezoneOffset() * 60000;
      date = new Date(date.getTime() + userTimezoneOffset);
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
      
    },
    validateDate(value) {
      if (!value || typeof value !== 'string') return '';
      
      const [year] = value.split('-').map(Number);

      // Verifique se o ano é válido
      if (year < 1800 || year > 3000) {
        return "Ano inválido.";
      }

      return true;
    },
    isExonerationDateValid(exonerationDate) {
      if (!exonerationDate || typeof exonerationDate !== 'string') return '';
      const item = this.items.find(i => i.is_editing || i === this.items[this.items.length - 1]);
      if (!item) return true;

      const [yearEx,monthEx, dayEx] = exonerationDate.split('-').map(Number);
      const [yearAd, monthAd, dayAd] = item.entryDate.split('-').map(Number);
      const exoneration = new Date(yearEx, monthEx - 1, dayEx);
      const admission = new Date(yearAd, monthAd - 1, dayAd);

      if (exoneration < admission) {
        return "Data de exoneração anterior a data de admissão"
      }

      return true
    },
    createEmptyCareer() {
    return {
      jobRegister: '',
      post: '',
      department: '',
      role: '',
      bond: '',
      entryDate: '',
      exoneration: '',
      isBoss: 'Não', //Valor Padrao 
      cca: '',
      bossSector: '',
      symbol: '',
      workCity: '',
      originOrganization: '',
      bonusPostRetirement: 'Não', //Valor Padrao
      is_editing: false,
      is_disabled: false,
    };
  },
  async fetchCareerData() {
  const urlGet = this.person?.ID ? `${urlbackend}/rh/person/${this.person.ID}` : `${urlbackend}/rh/person/`;

  try {
    const res = await axios.get(urlGet);
    if (res.status === 200 && res.data) {
      if (this.$parent || this.$root) {
        const person = res.data;
        person.birthday = new Date(person.birthday);
        this.fetchedPerson = person;
      }

      this.items = res.data.careers.map(el => ({
        ...el,
        is_disabled: false,
        is_editing: false,
      }));

      // Adiciona o objeto para um novo cargo no final do array
      this.items.push(this.createEmptyCareer());
    } else {
      this.items = [this.createEmptyCareer()];
    }
  } catch (error) {
    console.error('Erro ao buscar dados de carreira:', error);
    this.items = [this.createEmptyCareer()];
  }
},
    async confirmDialog() {
      this.dialog_update = false;
      if (this.actionToConfirm) {
        await this.actionToConfirm();
      }
      this.actionToConfirm = null;
    },
    cancelDialog() {
      this.dialog_update = false;
      this.actionToConfirm = null;
    },
    async confirmUpdateCargo(item) {
      this.dialog_phrase = 'Tem certeza que deseja atualizar este cargo?';
      this.dialog_update = true;
      this.itemToModify = item;
      this.actionToConfirm = this.updateCargo;
    },
    // Atualiza o cargo -> Atualiza os campos do cargo
    async updateCargo() {
      const item = this.itemToModify;

      if (item.jobRegister === '' || item.post === '' || item.department === '' || item.role === '' || item.bond === '' || item.entryDate === '' || item.isBoss === '' || item.cca === '' || item.symbol === '' || item.workCity === '' || item.bonusPostRetirement === '' || (item.isBoss === "Sim" && item.bossSector === '')) {
        alert('Preencha todos os campos obrigatórios.');
        return;
      }

      // Garante que a data de admissão esteja em formato ISO antes de enviar
      // if (item.entryDate) {
      //   const [day, month, year] = item.entryDate.split('/');
      //   item.entryDate = new Date(`${year}-${month}-${day}`);
      // }

      // Garante que a data de exoneração esteja em formato ISO antes de enviar
      if (!item.exoneration) {
        item.exoneration = null;
      }

      // Transforma o isBoss em booleano para enviar ao backend
      item.isBoss = item.isBoss === "Sim";

      // Transforma o bonusPostRetirement em booleano para enviar ao backend
      item.bonusPostRetirement = item.bonusPostRetirement === "Sim";

      // Retira os campos que não são necessários para enviar ao backend
      const cargoToSend = { ...item };
      delete cargoToSend.is_editing;
      delete cargoToSend.is_disabled;
      delete cargoToSend.createdAt;
      delete cargoToSend.updatedAt;
      delete cargoToSend.ID;
      delete cargoToSend.user;

      // Faz com que o exoneration não seja mandado para o backend, caso um cargo não tenha essa data.
      if (!cargoToSend.exoneration) delete cargoToSend.exoneration;

      // Envia o cargo atualizado ao backend - PUT
      try {
        const res = await axios.put(urlbackend + '/rh/career/' + item.ID, cargoToSend);
        if (res.status >= 200 && res.status <= 299) {
          item.is_editing = false;
          await this.fetchCareerData();
          this.$emit('update-careers', this.fetchedPerson.ID);
        }
      } catch (error) {
        console.error('Erro ao atualizar o cargo:', error);
        alert('Ocorreu um erro ao atualizar o cargo. Por favor, tente novamente.');
      }

      // Retorna a variável de confirmação de update para o estado inicial
      this.confirm_update = false;
    },
    cancelUpdate() {
      this.dialog_update = false;
      this.confirm_update = false;
    },
    async confirmDeleteItem(item) {
      this.dialog_phrase = 'Tem certeza que deseja excluir este item?';
      this.dialog_update = true;
      this.itemToModify = item;
      this.actionToConfirm = this.deleteItem;
    },
    async deleteItem() {
      const item = this.itemToModify;
      axios.delete(urlbackend + '/rh/career/' + item.ID).then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          const index = this.items.indexOf(item);
          if (index > -1) {
            this.items.splice(index, 1);
          }
        }
      }).catch((error) => {
        console.error('Erro ao excluir o item:', error);
        alert('Ocorreu um erro ao excluir o item. Por favor, tente novamente.');
      });
    },
    async confirmExonerateItem(item) {
      this.dialog_phrase = 'Tem certeza que deseja exonerar este cargo?';
      this.dialog_update = true;
      this.itemToModify = item;
      this.actionToConfirm = this.exonerateItem;
    },
    async exonerateItem() {
      const item = this.itemToModify;
      item.is_disabled = true;
      if (!item.exoneration) {
        item.exoneration = new Date();
      }

      // Cria uma cópia do item e remove os atributos desnecessários
      const itemToSend = { ...item };
      delete itemToSend.is_disabled;
      delete itemToSend.is_editing;
      delete itemToSend.createdAt;
      delete itemToSend.updatedAt;
      delete itemToSend.ID;

      try {
        const res = await axios.put(`${urlbackend}/rh/career/${item.ID}`, itemToSend);
        if (res.status >= 200 && res.status <= 299) {
          // Atualize a lista de itens
          await this.fetchCareerData();
          this.$emit('update-careers', this.fetchedPerson.ID);
        }
      } catch (error) {
        console.error('Erro ao exonerar o cargo:', error);
        alert('Ocorreu um erro ao exonerar o cargo. Por favor, tente novamente.');
      }
    },
    editItem(item) {
      //Copia o item para caso o usuário cancele a edição
      this.copia_item = { ...item }
      item.is_editing = true

      //Converte o isBoss para visualização do usuário
      item.isBoss = item.isBoss ? "Sim" : "Não";

      //Converte o bonusPostRetirement para visualização do usuário
      item.bonusPostRetirement = item.bonusPostRetirement ? "Sim" : "Não";

      //Converte as datas para o formato yyyy-mm-dd para visualização do usuário
      item.exoneration = this.formatNewDate(item.exoneration);
      // console.log(item.entryDate);
      item.entryDate = this.formatNewDate(item.entryDate);
      
    },
    async confirmRegistraCargo(item) {
      const requiredFields = [
        'jobRegister', 'post', 'department', 'role', 'bond', 'entryDate',
        'isBoss', 'cca', 'symbol', 'workCity', 'bonusPostRetirement'
      ];
      // Verifica se todos os campos obrigatórios estão preenchidos
      const isFieldEmpty = requiredFields.some(field => item[field] === '');
      const isBossSectorEmpty = item.isBoss === 'Sim' && item.bossSector === '';
      
      if (isFieldEmpty || isBossSectorEmpty) {
        alert('Preencha todos os campos obrigatórios.');
        return;
      }

      this.dialog_phrase = 'Tem certeza que deseja registrar este cargo e exonerar o cargo anterior?';
      this.dialog_update = true;
      this.itemToModify = item;
      this.actionToConfirm = this.registraCargo;
    },
    async registraCargo() {
  const item = this.itemToModify;

  const cargo = {
    jobRegister: item.jobRegister,
    post: item.post,
    department: item.department,
    role: item.role,
    bond: item.bond,
    entryDate: item.entryDate,
    is_disabled: false,
    is_editing: false,
    isBoss: item.isBoss === "Sim",
    cca: item.cca,
    bossSector: item.bossSector,
    symbol: item.symbol,
    workCity: item.workCity,
    originOrganization: item.originOrganization,
    bonusPostRetirement: item.bonusPostRetirement === "Sim",
    active: true,
    personId: this.person.ID,
  };

  this.items.splice(this.items.length - 1, 0, cargo);
  const cargoToSend = { ...cargo };
  delete cargoToSend.is_editing;
  delete cargoToSend.is_disabled;

  try {
    // Envia o cargo ao backend - POST
    const res = await axios.post(`${urlbackend}/rh/career`, cargoToSend);
    if (res.status >= 200 && res.status <= 299) {
      cargo.ID = res.data.ID;
      // Exonera o cargo anterior
      await this.fetchCareerData();
      this.$emit('update-careers', this.fetchedPerson.ID);
    }
  } catch (error) {
    console.error('Erro ao registrar o cargo:', error);
    alert('Ocorreu um erro ao registrar o cargo. Por favor, tente novamente.');
  }
},
    async confirmNotEditItem(item) {
      this.dialog_phrase = 'Tem certeza que deseja cancelar a edição?';
      this.dialog_update = true;
      this.itemToModify = item;
      this.actionToConfirm = this.notEditItem;
    },
    async notEditItem() {
      const item = this.itemToModify;
      const copia = this.copia_item;

      // Retorna os valores originais do item, antes da alteração do usuário.
      Object.keys(copia).forEach(key => {
        item[key] = copia[key];
      });
      item.is_editing = false;
    },
  }
}
</script>